export const SET_DATA_CHANNEL = 'SET_DATA_CHANNEL';
export const SET_BATTERY_STATUS = 'SET_BATTERY_STATUS';

export const SET_FULL_SCREEN_STATUS = 'SET_FULL_SCREEN_STATUS';
export const SET_HIDE_NAV_OPTIONS_STATUS = 'SET_HIDE_NAV_OPTIONS_STATUS';
export const SET_DRIVING_MODE_STATUS = 'SET_DRIVING_MODE_STATUS';
export const SET_NAV_CAMERA_HEIGHT = 'SET_NAV_CAMERA_HEIGHT';

export const SET_LOCAL_VOICE_VOLUME = 'SET_LOCAL_VOICE_VOLUME';
export const SET_NAV_SPEED = 'SET_NAV_SPEED';
export const SET_REMOTE_VOICE_VOLUME = 'SET_REMOTE_VOICE_VOLUME';

export const UPDATE_MICROPHONE = 'UPDATE_MICROPHONE';
export const UPDATE_SPEAKER = 'UPDATE_SPEAKER';
export const UPDATE_CAMERA = 'UPDATE_CAMERA';

export const SET_ROBOT_INFO = 'SET_ROBOT_INFO';
export const SET_AUTO_PARK_STATUS = 'SET_AUTO_PARK_STATUS';
export const SET_AUTO_PARK_ENABLED = 'SET_AUTO_PARK_ENABLED';
export const SET_CONTROLLER_STATUS = 'SET_CONTROLLER_STATUS';
