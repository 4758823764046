import {
	LocalTrackLabel,
	PilotPrimaryCamera,
	RemoteTrackKey,
	RobotNavCamera,
	RobotPrimaryCamera,
} from 'GoBeWebRTC/types';

/** A mapping of the mid in RTCSessionDescription, to a named media track key */
export const localTracksLabelMap: Record<string, LocalTrackLabel> = {
	local: PilotPrimaryCamera.LOCAL,
};

/** A mapping of the mid in RTCSessionDescription, to a named media track key */
export const remoteTracksMidsMap: Record<string, RemoteTrackKey> = {
	// TODO: uncomment
	0: 'audio',
	1: RobotPrimaryCamera.WIDE_CAM,
	// 3: RobotNavCamera.NAV_CAM,
	// 4: RobotPrimaryCamera.ZOOM_CAM,
};

/** Maps an array to key value dictionary */
export const arrayToKeyValueDict: (
	data: any[],
	getKey: (value: any) => string,
	map?: Record<string, any>
) => { [key: string]: any } = (data, getKey, map) =>
	Object.fromEntries(
		data.reduce((acc, value) => {
			let key = getKey(value);
			if (key) {
				if (map) key = map[key];
				acc.set(key, value);
			}
			return acc;
		}, new Map([]))
	);
