import React from 'react';
import {
	AutoDockingController,
	AutoDockingStatus,
} from '../../navigation/autoDocking/useAutoDockingController';
import './index.scss';

import dockingStageNavigatingIcon from 'images/docking-navigating.svg';
import dockingDetectionFailedIcon from 'images/docking-detection-failed.svg';
import dockingStagePositioningIcon from 'images/docking-positioning.svg';
import dockingSuccessfulIcon from 'images/docking-successful.svg';

type IConfig = {
	title: string;
	label: string;
	icon: string;
	actionRequired: React.ReactNode;
};
const DockingStatusConfig: Record<
	AutoDockingController['status']['stage'],
	Partial<IConfig & Record<AutoDockingController['status']['state'], Partial<IConfig>>> | null
> = {
	IDLE: null,
	STARTING: null,
	DETECTING: {
		title: 'Docking',
		icon: dockingStageNavigatingIcon,
		actionRequired: (
			<>
				Release the <span>P</span> key to stop.
			</>
		),
		IN_PROGRESS: {
			label: 'Detecting the dock...',
		},
		SUCCESS: {
			label: 'Dock detection Successful.',
		},
		FAILED: {
			title: 'Docking Failed',
			icon: dockingDetectionFailedIcon,
			actionRequired: (
				<>
					Reposition and press <span>P</span> to try again.
				</>
			),
		},
	},
	DOCKING: {
		title: 'Docking',
		icon: dockingStagePositioningIcon,
		IN_PROGRESS: {
			label: 'Navigating into the dock...',
			actionRequired: (
				<>
					Release the <span>P</span> key to stop.
				</>
			),
		},
		SUCCESS: {
			title: 'Docking Successful',
			icon: dockingSuccessfulIcon,
			actionRequired: (
				<>
					Release the <span>P</span> key to dismiss.
				</>
			),
		},
		FAILED: {
			title: 'Docking Failed',
			actionRequired: (
				<>
					Reposition and press <span>P</span> to try again.
				</>
			),
		},
	},
	STOPPING: null,
};

function getConfig({ stage, state }: AutoDockingController['status']) {
	const config = DockingStatusConfig[stage];
	if (config === null) return config;

	return {
		title: config[state]?.title ?? config.title,
		label: config[state]?.label ?? config.label,
		icon: config[state]?.icon ?? config.icon,
		actionRequired: config[state]?.actionRequired ?? config.actionRequired,
	} as IConfig;
}

type Props = {
	status: AutoDockingStatus;
};
export default function AutoDockingStatusIndicator({ status }: Props) {
	const config = getConfig(status);
	if (config === null) return null;

	// const statusMessage = statusConverter(`DOCK ${stage} ${state}`);
	return (
		<div className="dockingContainer">
			<div className="dockingTitle">{config.title}</div>
			<div className="dockingStatus">{config.label}</div>
			<img className="dockingIconWrapper" alt="" src={config.icon} />
			{config.actionRequired}
		</div>
	);
}
