import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk';
import {
	ClientConfiguration,
	DescribeSignalingChannelOutput,
	GetSignalingChannelEndpointOutput,
	ResourceARN,
	ResourceEndpoint,
	ResourceEndpointListItem,
} from 'aws-sdk/clients/kinesisvideo';
import {
	GetIceServerConfigRequest,
	GetIceServerConfigResponse,
} from 'aws-sdk/clients/kinesisvideosignalingchannels';
import { SignalingClient as AWSSignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc';
import {
	Credentials,
	SignalingClientConfig,
} from 'amazon-kinesis-video-streams-webrtc/lib/SignalingClient';
import { ISignalingClient } from './ISignalingClient';
import { WebRTCSessionConfiguration } from '../types';

/** Events that may be sent and/or received by the client */
enum SignalingEvent {
	Open = 'open',
	SDPOffer = 'sdpOffer',
	SDPAnswer = 'sdpAnswer',
	IceCandidate = 'iceCandidate',
	Close = 'close',
	Error = 'error',
}

/** AWS Kinesis signaling client */
export default class KVSSignaling implements ISignalingClient {
	iceServers: RTCIceServer[];
	isClosed: boolean = true;

	// Listeners
	onOpen: () => void;
	onSDPOffer: ((offer: RTCSessionDescription, remoteClientId: string) => void) | null;
	onSDPAnswer: ((answer: RTCSessionDescription) => void) | null;
	onIceCandidate: ((candidate: RTCIceCandidate) => void) | null;
	onClose: () => void;
	onError: (error: any) => void;

	private client: AWSSignalingClient;
	private credentials: Credentials;
	private region: string;
	private role: Role;
	private channelName: string;
	private clientId: string;

	constructor(readonly sessionConfiguration: WebRTCSessionConfiguration) {
		this.credentials = sessionConfiguration.credentials as Credentials;
		this.region = sessionConfiguration.region;
		this.role = sessionConfiguration.role as Role;
		this.channelName = sessionConfiguration.id!;
		this.clientId = sessionConfiguration.clientId!;
	}

	open = async () => {
		if (!this.isClosed) {
			console.warn('Cannot call signalingClient.open(), already opened');
			return;
		}

		const clientConfiguration: ClientConfiguration = {
			region: this.region,
			...this.credentials,
			correctClockSkew: true,
		} as ClientConfiguration;

		// Create KVS client
		const kinesisVideoClient = new KinesisVideo(clientConfiguration);

		// Get signaling channel ARN
		const describeSignalingChannelResponse: DescribeSignalingChannelOutput =
			await kinesisVideoClient
				.describeSignalingChannel({
					ChannelName: this.channelName,
				})
				.promise();
		const channelARN: ResourceARN = describeSignalingChannelResponse.ChannelInfo
			?.ChannelARN as ResourceARN;

		// Get signaling channel endpoints
		let getSignalingChannelEndpointResponse: GetSignalingChannelEndpointOutput =
			await kinesisVideoClient
				.getSignalingChannelEndpoint({
					ChannelARN: channelARN,
					SingleMasterChannelEndpointConfiguration: {
						Protocols: ['WSS', 'HTTPS'],
						Role: this.role,
					},
				})
				.promise();
		const endpointsByProtocol: { [id: string]: ResourceEndpoint } | undefined =
			getSignalingChannelEndpointResponse.ResourceEndpointList?.reduce(
				(endpoints: { [id: string]: ResourceEndpoint }, endpoint: ResourceEndpointListItem) => {
					endpoints[endpoint.Protocol as string] = endpoint.ResourceEndpoint as ResourceEndpoint;
					return endpoints;
				},
				{}
			);

		const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
			...clientConfiguration,
			endpoint: endpointsByProtocol?.HTTPS,
		});

		// Get ICE server configuration
		let getIceServerConfigResponse: GetIceServerConfigResponse =
			await kinesisVideoSignalingChannelsClient
				.getIceServerConfig({
					ChannelARN: channelARN,
				} as GetIceServerConfigRequest)
				.promise();

		this.iceServers = [];
		// TODO: Keep only UDP transport supported servers
		this.iceServers.push({
			urls: `stun:stun.kinesisvideo.${this.region}.amazonaws.com:443`,
		});
		getIceServerConfigResponse.IceServerList?.forEach((iceServer) =>
			this.iceServers.push({
				urls: iceServer.Uris as string | string[],
				username: iceServer.Username,
				credential: iceServer.Password,
			})
		);

		console.log('ICE SERVERS', this.iceServers);

		// Create Signaling Client
		this.client = new AWSSignalingClient({
			channelARN,
			channelEndpoint: endpointsByProtocol?.WSS,
			clientId: this.clientId,
			role: this.role,
			region: this.region,
			credentials: this.credentials,
			systemClockOffset: kinesisVideoClient.config.systemClockOffset,
		} as SignalingClientConfig);

		// Set event listeners
		Object.entries(SignalingEvent).forEach(([key, value]) => {
			this.client.on(value, this[`on${key}` as keyof ((...args: any[]) => void)]);
		});
		this.client.open();
	};

	sendSDPOffer = (sdpOffer: RTCSessionDescription, recipientClientId?: string | undefined) =>
		this.client.sendSdpOffer(sdpOffer, recipientClientId);

	sendSDPAnswer = (sdpOffer: RTCSessionDescription, recipientClientId?: string | undefined) =>
		this.client.sendSdpAnswer(sdpOffer, recipientClientId);

	sendICECandidate = (iceCandidate: RTCIceCandidate, recipientClientId?: string | undefined) =>
		this.client.sendIceCandidate(iceCandidate, recipientClientId);

	close = () => {
		if (this.isClosed) {
			return;
		}

		this.isClosed = true;
		this.client.close();
	};
}
