import { useEffect, useMemo, useRef, useState } from 'react';
import WebRTCSession from 'webRTC';
import { LocalMedia, RemoteMedia, WebRTCSessionConfiguration } from 'webRTC/types';
import { getStats } from './internals';
import { DataChannels, GoBeStatuses, RobotStatus } from './types';
import { arrayToKeyValueDict, localTracksLabelMap, remoteTracksMidsMap } from './utils';

/**
 * GoBeWebRTC factory.
 *
 * Returns a GoBe web rtc session.
 */
export default function useGoBeWebRTC(_configuration: WebRTCSessionConfiguration): {
	gobeWebRTC: WebRTCSession;
	pilotMedia: { [trackKey: string]: LocalMedia };
	robotMedia: { [trackKey: string]: RemoteMedia };
	dataChannels: { [label: string]: RTCDataChannel };
	robotStatus: RobotStatus | undefined;
} {
	const configuration = useRef(_configuration).current;
	const [pilotMedia, setPilotMedia] = useState<{ [trackKey: string]: LocalMedia }>({});
	const [robotMedia, setRobotMedia] = useState<{ [trackKey: string]: RemoteMedia }>({});
	const [dataChannels, setDataChannels] = useState<{ [label: string]: RTCDataChannel }>({});
	const [robotStatus, setRobotStatus] = useState<RobotStatus | undefined>();
	const gobeWebRTC = useMemo(() => {
		const gobeSession: WebRTCSession = new WebRTCSession({
			...configuration,
			localMediaConstraints: { audio: true, video: true },
			ondatachannel: [],
			dataChannels: [
				{
					label: DataChannels.NAV_DATACHANNEL,
					onmessage: (e) => {
						console.log('RECEIVED MESSAGE FROM DATACHANNEL', DataChannels.NAV_DATACHANNEL, e.data);
					},
				},
				{
					label: DataChannels.CONTROL_DATACHANNEL,
					onmessage: (e) => {
						console.log(
							'RECEIVED MESSAGE FROM DATACHANNEL control-datachannel',
							DataChannels.CONTROL_DATACHANNEL,
							e.data
						);
					},
				},
				{
					label: DataChannels.STATUS_DATACHANNEL,
					onmessage: ({ data }) => {
						try {
							data = JSON.parse(data);
							switch (data.type) {
								case GoBeStatuses.ROBOT_STATUS:
									setRobotStatus(data.data);
									break;
							}
						} catch (e) {
							console.error('Cannot process status message received', data, e);
						}
					},
				},
			],
			onLocalMediaError: (error) => {
				console.error('LOCAL MEDIA ERROR', error);
			},
			onSDPOfferExtensions: {
				gobeSwitching: async () => {
					// Roaming handling
					console.log('RECEIVED GOBE SWITCHING - ROAMING STARTED');
					const offer = await gobeSession.peerConnection.createOffer({
						iceRestart: true,
						offerToReceiveVideo: true,
						offerToReceiveAudio: true,
					});
					try {
						await gobeSession.peerConnection.setLocalDescription(offer);
					} catch (e) {
						console.error(e, offer, gobeSession.peerConnection.localDescription);
					}
					return gobeSession.signalingClient.sendSDPOffer(
						gobeSession.peerConnection.localDescription!
					);
				},
			},
		});
		gobeSession.setLocalMedia = (value: LocalMedia[]) => {
			gobeSession.localMedia = value;
			// Map local media to pilot media
			setPilotMedia(
				value
					? arrayToKeyValueDict(
							value,
							(localMedia) => localMedia?.media?.label!,
							localTracksLabelMap
					  )
					: {}
			);
		};
		gobeSession.setRemoteMedia = (value: RemoteMedia[]) => {
			gobeSession.remoteMedia = value;
			// Map remote media to robot media
			setRobotMedia(
				value
					? arrayToKeyValueDict(value, (media) => media?.transceiver?.mid!, remoteTracksMidsMap)
					: {}
			);
		};
		gobeSession.setDataChannels = (value: RTCDataChannel[]) => {
			gobeSession.dataChannels = value;
			// Map data channels to key-labelled data channels
			setDataChannels(value ? arrayToKeyValueDict(value, (channel) => channel?.label!) : {});
		};

		if (process.env.NODE_ENV !== 'production') {
			(window as any).getWebRTCStats = () => getStats(gobeSession.peerConnection);
		}

		return gobeSession;
	}, [configuration]);

	// Close when unmounting
	useEffect(() => {
		return () => {
			if (gobeWebRTC) gobeWebRTC.close();
		};
	}, [gobeWebRTC]);

	return { gobeWebRTC, pilotMedia, robotMedia, dataChannels, robotStatus };
}
