import KVSSignaling from './KVSSignaling';
import { ISignalingClient } from './ISignalingClient';
import { WebRTCSessionConfiguration } from '../types';

/**
 * Signaling client factory.
 */
export default function useSignalingClient(
	sessionConfiguration: WebRTCSessionConfiguration
): ISignalingClient {
	const signalingClient = new KVSSignaling(sessionConfiguration);

	return signalingClient as ISignalingClient;
}
