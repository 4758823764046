import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from 'actions/setParam';
import { SET_NAV_CAMERA_HEIGHT } from 'actions/types';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import DynamicMedia from 'components/dynamicMedia';
import { closeFullScreen, openFullscreen } from 'utils/fullScreen';
import ZoomTransition from '../../overlays/zoomTransition';
import { CameraConfig, PrimaryCameraState, RobotPrimaryCamera } from 'types';
import { SessionState } from '../../peerConnection/useCallerPeerConnection/peerConnection';

import exitFullscreenIcon from 'images/exit-fullscreen.svg';
import goFullscreenIcon from 'images/black-full-screen.svg';

type PropsFromParent = {
	primaryCameraState: PrimaryCameraState;
	onPlaybackToggle: (value: boolean) => void;
	mediaStream: MediaStream;
	sessionState: SessionState;
	cameraConfigs: Record<RobotPrimaryCamera, CameraConfig>;
	canShowLoadingIndicator: boolean;
};

const reduxConnector = connect(
	(state: AppRootState) => ({
		drivingMode: state.sessionState.drivingMode,
		remoteVoiceVolume: state.sessionState.remoteVoiceVolume,
		fullScreenStatus: state.sessionState.fullScreenStatus,
		navCameraHeight: state.sessionState.navCameraHeight,
		robot: state.sessionState.robot,
	}),
	{ setParameter }
);

export const VIDEO_SEPARATOR_HEIGHT = 6 * 2 + 8;

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

/** Video component for playing the video feed from robot's primary camera (zoom or wide cam) */
const RemotePrimaryCamVideo = ({
	mediaStream,
	setParameter,
	remoteVoiceVolume,
	drivingMode,
	sessionState,
	fullScreenStatus,
	navCameraHeight,
	primaryCameraState,
	onPlaybackToggle,
	robot,
	cameraConfigs,
	canShowLoadingIndicator: showsLoadingIndicator,
}: ComponentProps) => {
	const [isVideoLoading, setIsVideoLoading] = useState(true);

	const isSessionPaused = sessionState === 'Paused';
	const isSessionRetrying = sessionState === 'Retrying';

	const videoRef = useRef<HTMLVideoElement | null>(null);
	useEffect(() => {
		console.log('MEDIA STREAM', mediaStream);
		setIsVideoLoading(true);
		videoRef.current!.srcObject = mediaStream;
	}, [mediaStream]);

	useEffect(() => {
		videoRef.current!.volume = parseInt(remoteVoiceVolume) / 100;
	}, [remoteVoiceVolume, mediaStream]);

	const onCanPlay = () => {
		setIsVideoLoading(false);
		onPlaybackToggle(true);
	};

	// pause/play video depending on paused state of the session
	useEffect(() => {
		if (isVideoLoading) {
			onPlaybackToggle(false);
			return;
		}

		if (isSessionPaused) videoRef.current?.pause();
		else {
			videoRef.current
				?.play()
				.catch((error) => console.error('Unable to play RemotePrimaryCamVideo', error));
		}
	}, [isSessionPaused, isVideoLoading, onPlaybackToggle]);

	const resizeNavCamera = (value: number) => {
		setParameter('navCameraHeight', SET_NAV_CAMERA_HEIGHT, value);
	};

	const onFullScreenClick = () => {
		if (fullScreenStatus) {
			closeFullScreen();
		} else {
			openFullscreen();
		}
		window.dispatchEvent(new Event('fullscreenchange'));
	};

	const isVideoLoadingIndicatorVisible = !isSessionRetrying && isVideoLoading;
	const canShowZoomTransitionUI = !isVideoLoading && !isSessionRetrying;

	return (
		<div className={drivingMode ? 'remoteVideo' : 'remoteVideoFullScreen'}>
			<div className="fullScreenToggleButton" onClick={onFullScreenClick}>
				<img
					className="icon"
					alt=""
					src={fullScreenStatus ? exitFullscreenIcon : goFullscreenIcon}
				/>
			</div>

			<DynamicMedia
				// todo: Replace with `playing` event instead.
				// todo: The `onCanPlay` event is not always reliable - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/canplay_event
				onCanPlay={onCanPlay}
				autoPlay
				playsInline
				loop
				resizeNavCamera={resizeNavCamera}
				videoRef={videoRef}
				drivingMode={drivingMode}
				cameraConfig={cameraConfigs[primaryCameraState.currentPrimaryCamera]}
				fullScreenStatus={fullScreenStatus}
				navCameraHeight={navCameraHeight}
			/>

			{canShowZoomTransitionUI && <ZoomTransition primaryCameraState={primaryCameraState} />}
			{isVideoLoadingIndicatorVisible && showsLoadingIndicator ? (
				<div className="sessionStartContainer">
					<>
						<div className="sessionStartLoading" />
					</>
				</div>
			) : null}
		</div>
	);
};

export default reduxConnector(RemotePrimaryCamVideo);
