import { DEFAULT_TRANSCEIVER_SEND_ENCODINGS } from './fallbacks';
import { useLocalMedia } from './localMedia';
import usePeerConnection from './peerConnection';
import useSignalingClient from './signaling';
import { ISignalingClient } from './signaling/ISignalingClient';
import { DatachannelInit, LocalMedia, RemoteMedia, WebRTCSessionConfiguration } from './types';

/**
 * WebRTC session
 */
export default class WebRTCSession {
	signalingClient: ISignalingClient;
	peerConnection: RTCPeerConnection;
	localMedia: LocalMedia[] = [];
	remoteMedia: RemoteMedia[] = [];
	dataChannels: RTCDataChannel[] = [];
	remoteDataChannels: RTCDataChannel[] = [];

	setLocalMedia: (value: LocalMedia[]) => void = (value) => (this.localMedia = value);
	setRemoteMedia: (value: RemoteMedia[]) => void = (value) => (this.remoteMedia = value);
	setDataChannels: (value: RTCDataChannel[]) => void = (value) => (this.dataChannels = value);
	setRemoteDataChannels: (value: RTCDataChannel[]) => void = (value) =>
		(this.remoteDataChannels = value);

	/**
	 * Gets local media
	 */
	private getLocalMedia: () => Promise<void> = async () => {
		// Get local media
		this.setLocalMedia([
			await useLocalMedia(
				this.configuration.localMediaConstraints,
				this.configuration.preferredDevices ?? null,
				this.configuration.onLocalMediaError!
			),
		]);
	};

	/**
	 * Gets signaling client
	 */
	private getSignalingClient: () => void = () => {
		console.log('STEP: Getting signaling client');
		// Use signaling client
		this.signalingClient = useSignalingClient(this.configuration);
		this.signalingClient.onOpen = async () => {
			// Get user media
			await this.getLocalMedia();
			// Handle transceivers
			this.handleTransceivers();
			// Create an SDP offer to send to the master
			const offer = await this.peerConnection.createOffer({
				offerToReceiveVideo: true,
				offerToReceiveAudio: true,
			});
			console.log('SDP OFFER', offer);
			await this.peerConnection.setLocalDescription(offer);
			this.signalingClient.sendSDPOffer(this.peerConnection.localDescription!);
		};
		this.signalingClient.onSDPOffer = async (offer, remoteClientId) => {
			// Handle sdp offer
			if (this.configuration.onSDPOfferExtensions)
				await this.configuration.onSDPOfferExtensions[offer.type as string]();
		};
		this.signalingClient.onSDPAnswer = (answer) => {
			console.log('SDP ANSWER', answer);
			this.peerConnection.setRemoteDescription(answer);
		};
		console.log('SETTING UP ON ICE CANDIDATE');
		this.signalingClient.onIceCandidate = (candidate) => {
			console.log('NEW ICE CANDIDATE', candidate);
			this.peerConnection.addIceCandidate(candidate);
		};
		this.signalingClient.onClose = () => {
			// TODO: handle signaling client closing
		};
		this.signalingClient.onError = (error) => {
			// TODO: handle signaling client error
		};
	};

	/**
	 * Gets peer connection
	 */
	private getPeerConnection: () => void = () => {
		console.log('STEP: Getting peer connection', this.signalingClient);
		// Use peer connection
		this.peerConnection = usePeerConnection({
			iceServers: this.signalingClient.iceServers,
			iceTransportPolicy: this.configuration.iceTransportPolicy,
		} as RTCConfiguration);

		// Send any ICE candidates to the other peer
		this.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
			console.log('GENERATED ICE CANDIDATE', candidate);
			if (candidate) {
				// send the ICE candidates as they are generated.
				this.signalingClient.sendICECandidate(candidate);
			}
		});
		// As remote tracks are received
		this.peerConnection.addEventListener('track', (e: RTCTrackEvent) => {
			const { streams, track, transceiver } = e;
			this.setRemoteMedia([
				...this.remoteMedia,
				{
					streams,
					track,
					transceiver,
				},
			]);
		});
		// TODO: Add connection state change listeners
	};

	/**
	 * Gets remote data channels from peer connection
	 */
	private getRemoteDataChannels: () => void = () => {
		console.log('STEP: Getting remote datachannels');
		this.peerConnection.ondatachannel = (e: RTCDataChannelEvent) => {
			if (this.configuration.ondatachannel) {
				const datachannelMessageHandler = this.configuration.ondatachannel.find(
					(datachannelMessageHandler) => datachannelMessageHandler.label === e.channel.label
				);
				if (datachannelMessageHandler)
					e.channel.onmessage = datachannelMessageHandler.onmessage as
						| ((this: RTCDataChannel, ev: MessageEvent<any>) => any)
						| null;
			}
			this.setRemoteDataChannels([...this.remoteDataChannels, e.channel]);
		};
	};

	/**
	 * Creates data channels in peer connection
	 */
	private createDataChannels: () => void = () => {
		console.log('STEP: Creating datachannel');
		if (this.configuration.dataChannels)
			this.configuration.dataChannels.forEach((datachannelDef: DatachannelInit) => {
				const datachannel = this.peerConnection.createDataChannel(
					datachannelDef.label,
					datachannelDef.datachannelDict
				);
				if (datachannelDef.onmessage) datachannel.onmessage = datachannelDef.onmessage;
				this.setDataChannels([...this.dataChannels, datachannel]);
			});
	};

	private handleTransceivers: () => void = () => {
		// Handle peer connection transceivers
		this.localMedia.forEach((localMedia) => {
			localMedia.media?.stream.getTracks().forEach((track) => {
				switch (track.kind) {
					case 'video':
						this.peerConnection.addTrack(track, localMedia.media?.stream as MediaStream);
						break;
					case 'audio':
						this.peerConnection.addTrack(track, localMedia.media?.stream as MediaStream);
						break;
				}
			});
		});

		// this.peerConnection.addTransceiver('video', {
		// 	direction: 'recvonly',
		// 	sendEncodings: [DEFAULT_TRANSCEIVER_SEND_ENCODINGS],
		// 	streams: [],
		// });

		const supportsSetCodecPreferences =
			window.RTCRtpTransceiver && 'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
		if (supportsSetCodecPreferences) {
			const { codecs } = RTCRtpSender.getCapabilities('video') as RTCRtpCapabilities;
			console.log('Supported Codecs ', codecs);
			const rearrangedCodecs = [
				...codecs.filter((codec) => codec.mimeType === 'video/H264'),
				// ...codecs.filter((codec) => codec.mimeType === 'video/VP8'),
				// ...codecs.filter((codec) => !['video/H264', 'video/VP8'].includes(codec.mimeType)),
			];
			const transceivers = [
				...this.peerConnection.getTransceivers().filter((t) => t.sender.track?.kind === 'video'),
			];
			if (transceivers) {
				transceivers.forEach((transceiver: any) =>
					transceiver.setCodecPreferences(rearrangedCodecs)
				);
				console.log('Codec preferences has been set on transceiver');
			} else {
				console.error('transceiver has not been set up on peer connection yet');
			}
		} else {
			console.warn('Unfortunately, specifying preferred codec is not supported');
		}
	};

	/**
	 * Opens the session
	 */
	public open: () => void = async () => {
		// Setup connection
		this.getSignalingClient();
		// Open the signaling client
		await this.signalingClient.open();
		this.getPeerConnection();
		this.getRemoteDataChannels();
		this.createDataChannels();
	};

	/**
	 * Closes the session
	 */
	public close: () => void = () => {
		if (this.signalingClient) this.signalingClient.close();
		if (this.peerConnection) this.peerConnection.close();
		if (this.localMedia)
			this.localMedia.forEach((localMedia) =>
				localMedia?.media?.stream.getTracks().forEach((track) => track.stop())
			);
		if (this.remoteMedia) this.remoteMedia.forEach((media) => media.track.stop());
	};

	constructor(readonly configuration: WebRTCSessionConfiguration) {}
}
