export type IceTransportPolicy = 'all' | 'relay';

export type LocalTrackLabel = PilotPrimaryCamera.LOCAL;

export type RemoteTrackKey =
	| RobotPrimaryCamera.WIDE_CAM
	| RobotPrimaryCamera.ZOOM_CAM
	| RobotNavCamera.NAV_CAM
	| 'audio';

export enum PilotPrimaryCamera {
	LOCAL = 'local',
}

export enum RobotPrimaryCamera {
	WIDE_CAM = 'wide_cam',
	ZOOM_CAM = 'zoom_cam',
}

export enum RobotNavCamera {
	NAV_CAM = 'nav_cam',
}

export enum DataChannels {
	NAV_DATACHANNEL = 'nav-datachannel',
	CONTROL_DATACHANNEL = 'control-datachannel',
	STATUS_DATACHANNEL = 'status-datachannel',
}

/** Session Info that is generate from Roster page */
export type LocalSessionInfo = {
	awsClientConfiguration: AWSClientConfiguration;
	response: 'OK' | 'NOT_ALLOWED_BUSY' | 'NOT_ALLOWED_ERROR' | 'NOT_ALLOWED_NOT_READY';
	uuid: string;
	robot: { id: string; name: string; serialNumber: string };
	pilot: { id: string; name: string; avatar?: string | null };
	devices?: {
		camera?: { name?: string };
		microphone?: { name?: string; microphoneLevel?: number };
		speaker?: { name?: string; speakerLevel?: number };
	};
	robotStatus?: RobotStatus;
	capabilities: Capabilities;
};

export type RobotStatus = { battery: Battery; network: Network };

export type Capabilities = {
	super_zoom_1: boolean;
	mouse_control_with_joystick: boolean;
	mouse_control_with_slider: boolean;
	wide_camera_rotation: 0 | 90 | 180 | 270;
	zoom_camera_rotation: 0 | 90 | 180 | 270;
	nav_camera_rotation: 0 | 90 | 180 | 270;
	wide_camera_crop: { top: number; right: number; bottom: number; left: number };
};

export type Battery = {
	level: string;
	charging: boolean;
};

export type Network = {
	quality: number;
	ssid: string | null;
};

export type AWSClientConfiguration = {
	accessKeyId: string;
	secretAccessKey: string;
	iceTransportPolicy: IceTransportPolicy;
	region: string;
	id: string;
};

export enum GoBeStatuses {
	ROBOT_STATUS = 'robot_status',
	HANG_UP = 'hang_up',
}
