import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRootState } from 'reducers';
import { setParameter } from 'actions/setParam';
import { openFullscreen, isInFullscreen } from 'utils/fullScreen';
import { SET_FULL_SCREEN_STATUS } from 'actions/types';
import { LocalSessionInfo } from 'GoBeWebRTC/types';

function requestMediaDevicesPermission() {
	return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
		// TODO: Stop the media tracks associated with the stream?
		stream.getTracks().forEach((track) => track.stop());
	});
}

const reduxConnector = connect((state: AppRootState) => ({}), { setParameter });

type Props = ConnectedProps<typeof reduxConnector>;

const Lobby: React.FC<Props> = ({ setParameter }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const localSessionInfo =
		searchParams.get('sessionInfo') ??
		// TODO: remove later
		btoa(
			encodeURIComponent(
				JSON.stringify({
					awsClientConfiguration: {
						accessKeyId: 'AKIASFJQO4S7ODUOZTZD',
						secretAccessKey: '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',
						iceTransportPolicy: 'relay',
						region: 'eu-central-1',
						id: 'EUTestChannel',
					},
					response: 'OK',
					uuid: '36f1291c-e3d1-433b-bdc1-bc89b5d5da81',
					robot: {
						serialNumber: '350630000405',
						name: 'GoBe Robot 405',
						id: '350630000405',
					},
					pilot: {
						avatar: null,
						name: 'Nadir Tennci',
						id: 'nat@blue-ocean-robotics.com',
					},
					devices: {
						camera: {},
						microphone: {},
						speaker: {},
					},
					robotStatus: {
						currentState: 'available',
						battery: {
							level: '90',
							isCharging: false,
							charging: false,
							timestamp: '2022-11-01T16:14:53.072815',
						},
						network: { quality: 80, ssid: 'Blue Ocean Robotics' },
					},
					capabilities: {
						super_zoom_1: false,
						mouse_control_with_joystick: true,
						mouse_control_with_slider: false,
						wide_camera_rotation: 0,
						zoom_camera_rotation: 0,
						nav_camera_rotation: 0,
						wide_camera_crop: { top: 0, right: 0, bottom: 0, left: 0 },
					},
				} as LocalSessionInfo)
			)
		);

	useEffect(() => {
		const onFullscreenChanged = () => {
			if (document.fullscreenElement) {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', onFullscreenChanged);

		return () => {
			document.removeEventListener('fullscreenchange', onFullscreenChanged);
		};
	}, [setParameter]);

	useEffect(() => {
		if (localSessionInfo) {
			openFullscreen().finally(() => {
				// Immediately remember that the we are in fullscreen mode.
				// When the component unmounts and hte fullscreenchange event handler is removed
				//  we would have already saved the fullscreen state
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, isInFullscreen());

				requestMediaDevicesPermission().finally(() => {
					navigate('session/?sessionInfo=' + localSessionInfo);
					// NB: If permission request fails, the right error message will be displayed on Session page.
				});
			});
		} else {
			console.error("No 'sessionInfo' was found in url");
			// TODO: Show an error. Currently, the user will be staring at a blank black screen
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <></>;
};

export default reduxConnector(Lobby);
